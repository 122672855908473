import React from "react";

import ContactForm from "../../components/pages/TechnicShop/components/ContactForm";
import Page from "../../components/shared/Page";

const TechnicContactForm = () => {
    return (
        <Page>
            <ContactForm />
        </Page>
    );
};

export default TechnicContactForm;
